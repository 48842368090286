import React, {useContext} from "react";

import FirebaseContext from "./Firebase/FirebaseContext";
import {Redirect} from "react-router";
import LoggedIn from "./LoggedIn";
import {CircularProgress} from "@material-ui/core";

const LoggedInGuard = () => {

    let firebase = useContext(FirebaseContext);

    return (
      firebase === null ? <CircularProgress/> : (firebase.isLoggedIn() ? <LoggedIn/> : <Redirect to={"/login"}/>)
    )
  }
;

export default LoggedInGuard;

