import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDjW-dPncOnz7lPGRP8FQ_VcR5XmW0jsno",
  authDomain: "singknaben-counter.firebaseapp.com",
  databaseURL: "https://singknaben-counter.firebaseio.com",
  projectId: "singknaben-counter",
  storageBucket: "singknaben-counter.appspot.com",
  messagingSenderId: "258022308941",
  appId: "1:258022308941:web:3043556aca2db562c13004",
  measurementId: "G-SWBH9S2ZGX",
};

class Firebase {
  private auth: firebase.auth.Auth;
  private db: firebase.firestore.Firestore;
  private storage: firebase.storage.Storage;
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  doSignInWithEmailAndPassword = (password: string) =>
    this.auth.signInWithEmailAndPassword("socialmedia@singknaben.ch", password);

  doSignOut = () => this.auth.signOut();

  isInitialized = () =>
    new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });

  isLoggedIn = () => {
    return Boolean(this.auth.currentUser);
  };

  doGetEventSnapshot = (id: string) => this.db.collection("events").doc(id);
  doGetAllEventsSnapshot = () => this.db.collection("events");

  doCreateEvent = (name: string) =>
    this.db.collection("events").add({ name, visitors: 0 });

  doDeleteEvent = (id: string) => this.db.collection("events").doc(id).delete();

  doIncrement = (id: string) => {
    const increment = firebase.firestore.FieldValue.increment(1);

    const storyRef = this.db.collection("events").doc(id);

    return storyRef.update({ visitors: increment });
  };

  doDecrement = (id: string) => {
    const decrement = firebase.firestore.FieldValue.increment(-1);

    const storyRef = this.db.collection("events").doc(id);

    return storyRef.update({ visitors: decrement });
  };
}

export default Firebase;
