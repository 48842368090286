import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Login";
import * as ROUTES from "../constants/routes";
import LoggedInGuard from "./LoggedInGuard";
import FirebaseContext from "./Firebase/FirebaseContext";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const ScreenLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  let firebase = useContext(FirebaseContext);

  useEffect(() => {
    if (firebase !== null)
      firebase.isInitialized().then(() => {
        setFirebaseInitialized(true);
      });
  }, [firebase]);

  return firebaseInitialized ? (
    <Router>
      <Route path={ROUTES.LOGGEDIN} component={LoggedInGuard} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route exact path={ROUTES.LANDING} component={Login} />
    </Router>
  ) : (
    <ScreenLoader>
      <CircularProgress />
    </ScreenLoader>
  );
}

export default App;
