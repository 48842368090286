import React from "react";

import Navigation from "./Navigation";
import { Redirect, Route } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Login from "./Login";
import { createGlobalState } from "react-hooks-global-state";
import AllEvents from "./Events";
import SpecificEvent from "./Event";

export const useGlobalAppTitle = createGlobalState<{ title: string }>({
  title: "Anlässe",
}).useGlobalState;

const LoggedIn = () => {
  const [title] = useGlobalAppTitle("title");

  return (
    <React.Fragment>
      <Navigation title={title} />
      <Route path={ROUTES.EVENTS} exact component={AllEvents} />
      <Route path={ROUTES.EVENTS + "/:id"} exact component={SpecificEvent} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Redirect path={ROUTES.LOGGEDIN} to={ROUTES.EVENTS} />
    </React.Fragment>
  );
};
export default LoggedIn;
