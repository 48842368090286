import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FirebaseContext from "./Firebase/FirebaseContext";
import { EVENTS } from "../constants/routes";
import UnstyledLink from "./UnstyledLink";
import { EventType } from "../models/EventType";
import ConfirmationDialog from "./ConfirmationDialog";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const AllEvents = () => {
  const firebase = useContext(FirebaseContext);
  const [searchString, setSearchString] = useState("");
  const [addEventModal, setAddEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState("");
  const [events, setEvents] = useState<EventType[]>([]);
  if (firebase === null) return <div />;
  const eventSnapshot = firebase.doGetAllEventsSnapshot();
  eventSnapshot.onSnapshot((snapshot: any) => {
    if (!snapshot.empty) {
      let myEvents: EventType[] = [];
      snapshot.forEach((doc: any) => {
        myEvents.push({
          name: doc.data().name,
          visitors: doc.data().visitors,
          id: doc.id,
        });
      });
      setEvents(myEvents);
    }
  });

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Anlässe</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <List>
          {events
            .filter((value) => {
              if (searchString === "") return true;
              return value!
                .name!.toLocaleLowerCase()
                .includes(searchString.toLocaleLowerCase());
            })
            .map((event) => (
              <UnstyledLink to={EVENTS + "/" + event.id}>
                <ListItem>
                  <ListItemText
                    primary={event.name}
                    secondary={"Besucher: " + event.visitors}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </UnstyledLink>
            ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddEventModal(true)}
          style={{ marginTop: 10 }}
        >
          Event hinzufügen
        </Button>
      </Wrapper>
      <ConfirmationDialog
        open={addEventModal}
        title={"Neuer Event"}
        onClose={(confirmation: boolean) => {
          if (confirmation) firebase.doCreateEvent(newEvent);
          setAddEventModal(false);
        }}
        confirmText={"Erstellen"}
      >
        <TextField
          id="new-event-input"
          label="Event"
          value={newEvent}
          onChange={(e) => setNewEvent(e.target.value)}
        />
      </ConfirmationDialog>
    </React.Fragment>
  );
};

export default AllEvents;
