import React, {ReactNode} from "react"
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@material-ui/core";

interface ConfirmationDialogRawProps {
  open: boolean;
  title: string;
  children: ReactNode;
  confirmText: string;
  onClose: (confirmation: boolean) => void;
}

function ConfirmationDialog(props: ConfirmationDialogRawProps) {
  const handleCancel = () => {
    props.onClose(false);
  };

  const handleOk = () => {
    props.onClose(true);
  };


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={props.open}
      {...props}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleOk} color="primary">
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;