import React, { useContext, useState } from "react";
import { Button, Container, Snackbar, Typography } from "@material-ui/core";
import styled from "styled-components";
import FirebaseContext from "./Firebase/FirebaseContext";
import { RouteComponentProps, withRouter } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import ConfirmationDialog from "./ConfirmationDialog";
import { EventType } from "../models/EventType";
import { EVENTS } from "../constants/routes";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 400px;
`;

const IncrementButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0;
`;

const Spacing = styled.div`
  height: 20px;
`;

const VisitorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const SpecificEvent = (props: Props) => {
  const firebase = useContext(FirebaseContext);

  const [event, setEvent] = useState<EventType>({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [showUpdatedError, setShowUpdatedError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  if (firebase === null) return <div />;
  const eventSnapshot = firebase.doGetEventSnapshot(props.match.params.id);

  eventSnapshot.onSnapshot((snapshot: any) => {
    if (!snapshot.empty) {
      let myEvent: EventType = {};
      myEvent = snapshot.data();
      myEvent.id = snapshot.id;
      setEvent(myEvent);
    }
  });

  const incrementNumber = () => {
    firebase
      .doIncrement(props.match.params.id)
      .then(() => setShowUpdated(true))
      .catch(() => setShowUpdatedError(true));
  };
  const decrementNumber = () => {
    firebase
      .doDecrement(props.match.params.id)
      .then(() => setShowUpdated(true))
      .catch(() => setShowUpdatedError(true));
  };

  const handleConfirmation = (confirmation: boolean) => {
    setShowDeleteConfirmation(false);
    if (confirmation) {
      firebase
        .doDeleteEvent(props.match.params.id)
        .then(() => setShowSuccess(true));
      props.history.push(EVENTS);
    }
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h5">{event.name}</Typography>
        <Spacing />
        <VisitorRow>
          <Typography variant="h2">{event.visitors} Besucher</Typography>
        </VisitorRow>
        <IncrementButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => incrementNumber()}
          >
            +1
          </Button>
          <Spacing />
          <Button
            variant="contained"
            color="primary"
            onClick={() => decrementNumber()}
          >
            -1
          </Button>
        </IncrementButtonContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 50,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            style={{ marginLeft: 10 }}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Löschen
          </Button>
        </div>
      </Wrapper>
      <Snackbar
        open={showUpdated}
        autoHideDuration={1000}
        onClose={() => setShowUpdated(false)}
      >
        <Alert onClose={() => setShowUpdated(false)} severity="success">
          Besucher aktualisiert
        </Alert>
      </Snackbar>
      <Snackbar
        open={showUpdatedError}
        autoHideDuration={1000}
        onClose={() => setShowUpdatedError(false)}
      >
        <Alert onClose={() => setShowUpdatedError(false)} severity="warning">
          Besucher nicht aktualisiert
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Anlass erfolgreich gelöscht
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Anlass konnte nicht gelöscht werden
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title={"Löschen bestätigen"}
        onClose={(confirmation: boolean) => handleConfirmation(confirmation)}
        confirmText={"Löschen"}
      >
        Bist du dir sicher, dass du diesen Anlass löschen möchtest?
      </ConfirmationDialog>
    </React.Fragment>
  );
};
export default withRouter(SpecificEvent);
