import React, { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Menu } from "@material-ui/icons";
import FirebaseContext from "./Firebase/FirebaseContext";
import UnstyledLink from "./UnstyledLink";
import { EVENTS } from "../constants/routes";
import EventIcon from "@material-ui/icons/Event";

interface Props extends RouteComponentProps {
  title: string;
}

const Navigation = (props: Props) => {
  let [openDrawer, setOpenDrawer] = useState(false);

  let firebase = useContext(FirebaseContext);

  const onSignOut = () => {
    if (firebase === null) {
      console.log("firebase is null");
      return;
    } else {
      firebase
        .doSignOut()
        .then(() => props.history.push("/login"))
        .catch((reason) => console.log(reason.message));
    }
  };

  return (
    <div>
      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List component="nav" aria-label="main mailbox folders">
            <UnstyledLink to={EVENTS} onClick={() => setOpenDrawer(false)}>
              <ListItem button>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
            </UnstyledLink>
            <Divider />
            <ListItem button onClick={() => onSignOut()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
      <AppBar position="static" style={{ height: "auto" }}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Menu onClick={() => setOpenDrawer(true)} />
          </IconButton>
          <Typography variant="h6">
            <span>{props.title}</span>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Navigation);
